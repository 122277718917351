import { gql } from 'apollo-boost';

export const DELETE_DANCER_IMAGES = gql`
  mutation DancerDeleteImages($idArray: [ID!]!, $dancerId: ID!) {
    dancer_delete_images(input: { dancerId: $dancerId, imageIds: $idArray }) {
      dancer {
        first_image {
          id
          thumb
          medium
          is_verified
        }
        images {
          id
          thumb
          large
          is_verified
        }
      }
    }
  }
`;
