import { SHIFT_APPLICATION_FRAGMENT } from 'graphql/fragments/shift-application-fragment';
import { DANCER_GRID_FRAGMENT } from '../fragments/dancer-grid-fragment';
import { gql } from 'apollo-boost';

export const SHIFT_APPLICATIONS_GQL = (isAudition) => gql`
  ${DANCER_GRID_FRAGMENT}
  ${SHIFT_APPLICATION_FRAGMENT}
  query ShiftApplications(    
    $now: DateTime!
    $cursor: String!
    $businessId: ID!
    $status: [ShiftApplicationStatus!]
    $search: String
    $isAdmin: Boolean!
    $hasLocation: Boolean!
    $blockedByBusinessId: ID
    $inGroupBusinessId: ID
    $invited: Boolean
    $first: Int!
  ) {
    shift_applications(
      after: $cursor
      first: $first
      filters: {
        status: $status
        shiftEndedAfter: $now
        businessId: $businessId
        dancerNameLike: $search
        checkedIn: false
        checkedOut: false
        invited: $invited
        ${!isAudition ? 'excludedTypes' : 'includedTypes'}: [AUDITION]
      }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      nodes {       
        ...shiftApplicationFragment
        shift {
          id
          shift_type
          start_time
          end_time
          hardcapped
          slots
          accepted_shift_applications_count
          checked_in_shift_applications_count
          checked_out_shift_applications_count
          standby_shift_applications_count
        }
        dancer {
          ...DancerGridItem
        }
      }
    }
  }
`;
