import { useState } from 'react';
import * as PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import S from './photo-verification-list.module.scss';
import { PhotoVerificationItem } from './photo-verification-item';
import { LoadingDisco, LoadingDiscoWithContainer } from '../../../common/loading/loading-disco';
import { GET_PROFILE_PICTURES_LIST } from '../../../../graphql/queries/profile-pictures-list';
import { logError } from '../../../../helpers/errors/bug-report';
import { PHOTO_VERIFICATION_REJECTION_REASON_TRANSLATION } from 'helpers/constants';
import { PROFILE_PICTURE_APPROVE, PROFILE_PICTURE_REJECT } from 'graphql/mutations/profile-picture';
import { useModal } from 'components/global-hooks';

const PhotoVerificationList = ({ stage, search = null, sortOrder, includeIncomplete, includeRejected }) => {
  const { t } = useTranslation();
  const [pageInfo, setPageInfo] = useState({ endCursor: '', hasNextPage: false });
  const { loading, error, data, called, fetchMore } = useQuery(GET_PROFILE_PICTURES_LIST, {
    fetchPolicy: 'network-only',
    variables: { isVerified: stage, first: 20, dancer: true, search, sortOrder, includeIncomplete, includeRejected },
    onCompleted: (data) => {
      console.log('oncompleted triggered');
      setPageInfo(data.profile_pictures.pageInfo);
    },
    onError: (err) => logError(err, 'GET_PROFILE_PICTURES_LIST', PhotoVerificationList.displayName),
  });
  const {closeModal } = useModal();

  const updateCache = (cache, imageId) => {
    const cachedData = cache.readQuery({
      query: GET_PROFILE_PICTURES_LIST,
      variables: { isVerified: stage, first: 20, dancer: true, search, sortOrder, includeIncomplete, includeRejected },
    });

    const updatedNodes = cachedData.profile_pictures.nodes.filter(({ id }) => id !== imageId);
    cache.writeQuery({
      query: GET_PROFILE_PICTURES_LIST,
      variables: { isVerified: stage, first: 20, dancer: true, search,  sortOrder, includeIncomplete, includeRejected },
      data: {
        profile_pictures: {
          ...cachedData.profile_pictures,
          nodes: [...updatedNodes],
        },
      },
    });
  };

  const [rejectVerification, { loading: rejecting }] = useMutation(PROFILE_PICTURE_REJECT, {
    update: (cache, { data }) => {
      updateCache(cache, data.profile_picture_reject.image.id);
    },
    onCompleted: () => {
      toast.success(t('photoVerificationPage.reject.success'));
    },
  });

  const [approveVerification, { loading: approving }] = useMutation(PROFILE_PICTURE_APPROVE, {
    update: (cache, { data }) => {
      updateCache(cache, data.profile_picture_approve.image.id);
    },
    onCompleted: () => {
      toast.success(t('photoVerificationPage.approve.success'));
    },
  });

  const getMore = async () =>
    await fetchMore({
      variables: {
        first: 10,
        isVerified: stage,
        dancer: true,
        search: search,
        after: pageInfo.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;

        setPageInfo(fetchMoreResult.profile_pictures.pageInfo);

        return {
          profile_pictures: {
            ...fetchMoreResult.profile_pictures,
            nodes: [...previousResult.profile_pictures.nodes, ...fetchMoreResult.profile_pictures.nodes],
          },
        };
      },
    });

  const handleSubmitVerification = ({ id, isVerified, rejectionReason }) => {
    if (!isVerified) {
      rejectVerification({
        variables: {
          id,
          rejectionReason,
          rejectionText: PHOTO_VERIFICATION_REJECTION_REASON_TRANSLATION[rejectionReason],
        },
      });
    } else {
      approveVerification({ variables: { id } });
    }
    closeModal();
  };

  if (loading) return <LoadingDiscoWithContainer />;
  if (error) return <h1 className={S.errorPage}>{t('errors.generic')}</h1>;
  if (data?.totalCount === 0) {
    return <h1 className={S.h1Heading}>{t('common.no_pending_verifications')}</h1>;
  }

  return (
    <div className={S.photoVerificationList}>
      <>
        {data?.profile_pictures?.nodes.map((picture, idx) => (
          <PhotoVerificationItem
            className={idx % 2 !== 0 ? 'bg-slate-800' : ''}
            key={`picture_${picture.id}`}
            submitVerification={handleSubmitVerification}
            updating={rejecting || approving}
            {...{ picture }}

          />
        ))}

        <div className='flex mx-auto justify-center my-8'>
          {!called && loading ? (
            <LoadingDisco />
          ) : (
            !loading &&
            pageInfo.hasNextPage && (
              <button
                onClick={getMore}
                className='finline px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
              >
                {t('common.load_more')}
              </button>
            )
          )}
        </div>
      </>
    </div>
  );
};

PhotoVerificationList.displayName = 'PhotoVerificationListPresenter';
PhotoVerificationList.propTypes = {
  stage: PropTypes.string,
  sortOrder: PropTypes.string.isRequired,
  includeIncomplete: PropTypes.bool.isRequired,
  includeRejected: PropTypes.bool.isRequired,
  search: PropTypes.string
};

export { PhotoVerificationList };
