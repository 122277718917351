import { useMutation } from '@apollo/react-hooks';
import React, { useCallback } from 'react';
import * as PageChatCtx from 'components/pages-login/chat/chat-ctx';
import { CREATE_MESSAGE } from 'graphql/mutations/message-create';
import { GET_MESSAGES_PAGINATED } from 'graphql/queries/messages-query';
import { checkForFilterError } from 'helpers/filtered-words';
import { useTranslation } from 'react-i18next';
import useSuggestions, { SuggestionListType } from 'helpers/hooks/useSuggestions';
import S from './chat-msg-footer.module.scss';
import { InputFormText } from '../../../common/form/form-parts/input-form-text';
import RichToast from 'components/common/rich-toast';
import { toast } from 'react-toastify';
import { ApolloError } from 'apollo-boost';
import { type MsgSender } from 'types/conversation';

type ChatMsgFooterProps = {
  target: MsgSender;
  conversationId: string;
};

const ChatMsgFooter: React.FC<ChatMsgFooterProps> = ({ target, conversationId }) => {
  const {
    dispatch,
    state: { message },
  } = PageChatCtx.usePageChatCtx();
  const { t } = useTranslation();

  const { checkText } = useSuggestions(SuggestionListType.MANAGER);

  const handleError = (error: ApolloError | Error) => {
    const filterError = checkForFilterError(error, t('chatPage.send.error'));
    toast.error(<RichToast title={filterError.title} message={filterError.message} />);
  };

  const [createMessage, { loading: processingSend }] = useMutation(CREATE_MESSAGE, {
    update: (
      cache: any,
      {
        data: {
          message_create: { message },
        },
      }
    ) => {
      const data = cache.readQuery({ query: GET_MESSAGES_PAGINATED, variables: { conversationId, last: 20 } });
      cache.writeQuery({
        query: GET_MESSAGES_PAGINATED,
        variables: { conversationId, last: 20 },
        data: {
          messages_paginated: {
            __typename: 'MessageConnection',
            nodes: [...data.messages_paginated.nodes, message],
          },
        },
      });
    },
    onCompleted: () => {
      dispatch(PageChatCtx.setChatFields({ message: '', scrollToBottom: true }));
    },
    onError: handleError,
  });

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (message) {
        try {
          await checkText(message);
          await createMessage({ variables: { conversationId, message } });
        } catch (error) {
          handleError(error as Error);
        }
      }
    },
    [conversationId, createMessage, message]
  );

  const handleOnFocus = () => {
    dispatch(PageChatCtx.setChatFields({ firstUnreadId: null }));
  };

  return (
    <form onSubmit={handleSubmit} className={S.chatForm}>
      <InputFormText
        name='chatMsg'
        value={message}
        hasError={false}
        handleFocus={handleOnFocus}
        isDisabled={processingSend}
        placeholder={t('common.message_user', { user: target?.name })}
        handleChange={({ target: { value } }: { target: { value: string } }) =>
          dispatch(PageChatCtx.setChatField('message', value))
        }
      />
      <button type='submit' disabled={processingSend} className={S.filledBtnPink}>
        {t('common.send')}
      </button>
    </form>
  );
};

ChatMsgFooter.displayName = 'ChatMsgFooter';

export { ChatMsgFooter };
