import React, { useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import { FaPen } from 'react-icons/fa';

import S from './group-name.module.scss';
import { GroupNameForm } from './group-name-form';

const GroupName = ({ name = '', groupId }) => {
  const [isView, setIsView] = useState(true);
  const handleEdit = useCallback(() => setIsView(false), []);

  return (
    <div className={S.groupDetailSubHeading}>
      {isView ? (
        <h1 className={S.groupDetailHeading} title={name}>
          {name}
          <FaPen onClick={handleEdit} />
        </h1>
      ) : (
        <GroupNameForm name={name} groupId={groupId} onClose={() => setIsView(true)} />
      )}
    </div>
  );
};

GroupName.displayName = 'GroupName';

GroupName.propTypes = {
  name: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
};

export { GroupName };
