import React, { SyntheticEvent } from 'react';
import ColorButton from 'components/common/buttons/color-button';
import { GetstreamPost } from 'types/getstream';
import { getFormattedJsTime } from 'helpers/times';
import { CheckboxSpan } from 'components/common/checkboxes/checkbox-span';
import { GETSTREAM_POST_STATUS } from 'helpers/enums';
import { useTranslation } from 'react-i18next';
import { FaPlay } from 'react-icons/fa';
import { parseText } from 'helpers/feed';
import DancerProfileImage from 'components/common/images/dancer-profile-image';

interface Props {
  post: GetstreamPost;
  onApprove: (id: string) => void;
  onReject: (id: string) => void;
  onView: (id: string) => void;
  onDelete: (id: string) => void;
  onToggleComments: (event: React.MouseEvent<HTMLInputElement>) => Promise<void>;
}

const PostItem: React.FC<Props> = ({ post, onApprove, onReject, onView, onDelete, onToggleComments }) => {
  const { t } = useTranslation();
  const setFallbackImg = (e: SyntheticEvent): void => {
    (e.target as HTMLImageElement).src = `${window.location.origin}/img/video-placeholder.png`;
  };
  const getThumbnail = (url: string): string => {
    const [mediaUrl, transformation] = url.split('?');
    return post.media[0].media_type === 'PHOTO'
      ? url
      : `${mediaUrl}/ik-thumbnail.jpg${transformation ? '?' + transformation : ''}`;
  };
  const user = post.user || {
    name: '[DELETED_USER]',
  };

  return (
    <div className='flex flex-row p-4 w-full hover:bg-gray-800 border-b-2 border-gray-800 last:border-b-0'>
      <div className='flex flex-row w-2/5 overflow-hidden'>
        <div className='max-w-sm w-24 rounded-md mr-4 self-center'>
          <DancerProfileImage alt='' image={user.first_image} size='thumb' />
        </div>
        <div className='self-center max-w-sm'>
          <div className='font-bold'> {user.name} </div>
          {user.getstream_user && <div className='text-gray-500 truncate'> @{user.getstream_user.getstream_id} </div>}
          <div className='mt-1 text-gray-300'>
            {t('social.posted')}: <span className='font-bold'>{getFormattedJsTime(post.time)}</span>{' '}
          </div>
          {post.moderated_by && (
            <div className='text-gray-300'>
              {t('social.moderated')}: <span className='font-bold'>{getFormattedJsTime(post.moderated_at)}</span>
              <div className='font-bold'>
                {t(`common.${post.status.toLowerCase()}`)} by {post.moderated_by.name} ({post.moderated_by.email})
              </div>
            </div>
          )}
          {post.status === GETSTREAM_POST_STATUS.APPROVED && (
            <div className='mt-2 flex items-center'>
              <CheckboxSpan
                id={post.id}
                value={!!post.comments_disabled}
                isChecked={!!post.comments_disabled}
                isForm={true}
                onClick={onToggleComments}
              />
              <div className={'ml-2'}>{t('social.comments.disabled')}</div>
            </div>
          )}
        </div>
      </div>

      <div className='flex w-32 mx-10 justify-center'>
        {post.media.length >= 1 && (
          <div className='flex relative justify-center items-center '>
            {post.media[0].media_type === 'VIDEO' && <FaPlay size='20' className='absolute' />}
            <img src={getThumbnail(post.media[0]?.url)} className='w-full' onError={setFallbackImg} />
            {post.media.length > 1 && (
              <div className='flex w-full h-full absolute top-0 bg-black/50 justify-center items-center font-bold'>
                +{post.media.length - 1}
              </div>
            )}
          </div>
        )}
      </div>

      <div className=' max-w-sm self-center mr-10'> {parseText(post.text, 120, { link: 'text-blue-500' }).text} </div>

      <div className='self-center ml-auto'>
        <div className='flex flex-col space-y-2'>
          <ColorButton color={'blue'} type={'inverted'} className='uppercase w-24' onClick={onView.bind(this, post.id)}>
            {t('common.view')}
          </ColorButton>
          {post.status === GETSTREAM_POST_STATUS.REJECTED && (
            <ColorButton
              color={'red'}
              type={'inverted'}
              className='uppercase w-24'
              onClick={onDelete.bind(this, post.id)}
            >
              {t('common.delete')}
            </ColorButton>
          )}
          {post.moderated_by == null && (
            <>
              <ColorButton
                color={'green'}
                type={'inverted'}
                className='uppercase w-24'
                onClick={onApprove.bind(this, post.id)}
              >
                {t('common.approve')}
              </ColorButton>
            </>
          )}
          {post.status !== GETSTREAM_POST_STATUS.REJECTED && (
            <ColorButton
              color={'red'}
              type={'inverted'}
              className='uppercase w-24'
              onClick={onReject.bind(this, post.id)}
            >
              {t('common.reject')}
            </ColorButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default PostItem;
