import React, { useCallback } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useBusiness, useUser } from 'graphql/graph-hooks';
import * as PageChatCtx from 'components/pages-login/chat/chat-ctx';
import * as CachedConvos from 'graphql/cache/conversations';
import { DancerMenu } from 'components/common/dancer-action-menu/dancer-action-menu';

import S from './chat-msg-head.module.scss';
import { setChatFields } from '../chat-ctx';
import DancerProfileImage from 'components/common/images/dancer-profile-image';
import type { Image as ImageType } from 'types';

type blockedByType = {
  id: string;
  name: string;
};

type ChatMsgHeadProps = {
  id: string;
  name: string;
  email?: string;
  first_image: ImageType;
  isAdmin: boolean;
  businessId: string;
  conversationId: string;
  suspended: boolean;
  blocked_by: blockedByType | null;
  in_group: boolean;
  avatarOnClick: any;
};

const ChatMsgHead: React.FC<ChatMsgHeadProps> = ({
  id: receiverId,
  name,
  email,
  first_image,
  conversationId,
  businessId,
  suspended,
  blocked_by,
  in_group: inGroup,
  avatarOnClick,
}) => {
  const CLIENT = useApolloClient();
  const { dispatch } = PageChatCtx.usePageChatCtx();
  const { admin: isAdmin, id: userId } = useUser();

  const { id: currentBusinessId } = useBusiness();
  const { t } = useTranslation();

  const removeDancer = useCallback(() => {
    dispatch(
      setChatFields({
        conversationId: null,
        targetId: null,
        message: '',
        firstUnreadId: null,
        lastMsgId: null,
      })
    );

    const LIST = CachedConvos.getCachedConversations(CLIENT, businessId, userId, isAdmin, currentBusinessId);
    CachedConvos.setCachedConversations(
      CLIENT,
      businessId,
      userId,
      isAdmin,
      currentBusinessId,
      LIST.filter(({ id }) => id !== conversationId)
    );
  }, [dispatch, CLIENT, businessId, userId, isAdmin, conversationId, currentBusinessId]);

  return (
    <div className={S.msgReceiver}>
      <div className={S.imgDivWrapper} onClick={() => avatarOnClick(receiverId)}>
        <DancerProfileImage
          classname={classNames({ [S.suspended]: suspended, [S.inGroup]: inGroup })}
          image={first_image}
          size='thumb'
          alt={name}
        />
      </div>
      <div className={S.msgReceiverDetails}>
        <h4>
          {name}
          <div className={S.discipline}>
            {suspended && <span className={S.suspended}>({t('common.paused').toUpperCase()})</span>}
            {blocked_by && (
              <span className={S.blocked}>
                ({t('dancerPage.blocked_by')} {blocked_by.name})
              </span>
            )}
          </div>
        </h4>
        {isAdmin && <p>{email}</p>}
      </div>

      <DancerMenu dancerId={receiverId} removeDancer={removeDancer} businessId={currentBusinessId} />
    </div>
  );
};

export { ChatMsgHead };
