import React, { useMemo, useRef } from 'react';
import * as PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import S from './chat-user-list.module.scss';
import { ChatListItem } from './chat-user-item';
import { usePageChatCtx } from '../chat-ctx';
import { sortAndFilterConversations } from '../chat-services';
import { LoadingSpinner } from 'components/common/loading/loading-spinner';
import type { ConversationTypes } from 'types/conversation';

type ChatUserListProps = {
  conversations: ConversationTypes[];
  avatarOnClick: () => void;
  isLoading: boolean;
  isAdminChat: boolean;
  isAdmin: boolean;
  onScrollBottom: () => void;
  error: string | undefined;
};

export const ChatUserList: React.FC<ChatUserListProps> = ({
  conversations,
  avatarOnClick,
  onScrollBottom,
  isLoading,
  isAdminChat,
  isAdmin,
  error,
}): React.ReactElement => {
  const { t } = useTranslation();
  const {
    state: { conversationId, filter },
  } = usePageChatCtx();

  const CHAT_LIST = useMemo(() => sortAndFilterConversations(conversations, filter), [conversations, filter]);

  const listRef = useRef<any>();

  const handleOnScroll = () => {
    if (listRef.current.scrollHeight - (listRef.current.scrollTop + listRef.current.clientHeight) < 50) {
      onScrollBottom();
    }
  };

  return (
    <div className={S.chatList} onScroll={handleOnScroll} ref={(e) => (listRef.current = e)}>
      {conversations.length === 0 ? (
        <h3 className={S.warningMsg}>{t('chatPage.no_conversations')}</h3>
      ) : (
        <>
          <ul className={S.chatUserList}>
            {CHAT_LIST &&
              CHAT_LIST.map((chatObj) => {
                const isSelected = chatObj.id === conversationId;
                return (
                  <ChatListItem
                    key={`chat_member_${chatObj.id}`}
                    isSelected={isSelected}
                    {...chatObj}
                    business_unread_messages_count={
                      chatObj.id !== conversationId || (!isAdminChat && isAdmin)
                        ? chatObj.business_unread_messages_count
                        : 0
                    }
                    avatarOnClick={avatarOnClick}
                  />
                );
              })}
          </ul>
          {
            <p
              className={classNames(S.warningMsg, {
                [S.hidden]: !error && !isLoading,
                [S.errorMsg]: error,
              })}
            >
              {error ? (
                error
              ) : (
                <div className={S.spinnerWrapper}>
                  <LoadingSpinner style={S.spinner} />
                </div>
              )}
            </p>
          }
        </>
      )}
    </div>
  );
};

ChatUserList.displayName = 'ChatUserList';

ChatUserList.propTypes = {
  conversations: PropTypes.array.isRequired,
};
