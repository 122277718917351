import { FC } from 'react';
import { FaCheck, FaClock, FaStar } from 'react-icons/fa';

import { BOOKING_STATUS } from 'helpers/enums';
import type { Image as ImageType } from 'types';
import DancerProfileImage from 'components/common/images/dancer-profile-image';

interface Props {
  name: string;
  inGroup: boolean;
  isRated: boolean;
  image: ImageType;
  size: 'thumb' | 'medium' | 'large';
  status: BOOKING_STATUS;
  handleSelection: () => void;
}

const DancerImgWithStatus: FC<Props> = ({ name, inGroup, image, size, status, isRated, handleSelection }) => {
  let state, color;

  if (isRated) {
    state = 'rated';
    color = 'bg-blue-400';
  } else if (status === BOOKING_STATUS.CHECKED_IN) {
    state = 'time';
    color = 'bg-rose-400';
  } else if (status === BOOKING_STATUS.CHECKED_OUT) {
    state = 'done';
    color = 'bg-green-800';
  }

  return (
    <div
      className={`relative cursor-pointer border-2 w-20 mr-5 ${inGroup && 'border-green-500'} 
      }`}
    >
      <DancerProfileImage alt={name} image={image} size={size} onClick={handleSelection} />
      <div className={`flex absolute bottom-0 justify-center items-center w-full p-1 ${color}`}>
        {state === 'rated' && <FaStar />}
        {state === 'done' && <FaCheck />}
        {state === 'time' && <FaClock />}
      </div>
    </div>
  );
};

export default DancerImgWithStatus;
