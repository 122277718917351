import * as React from 'react';
import classNames from 'classnames';
import S from './loading-spinner.module.scss';

const LoadingSpinner = ({ style }) => (
  <div className={S.spinnerWrapper}>
    <div className={classNames([S.spinner, style])} />
  </div>
);

LoadingSpinner.displayName = 'LoaderPresenter';

export { LoadingSpinner };
