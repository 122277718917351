import { GET_VERIFICATION_FRAGMENT } from '../fragments/verification-fragment';
import { gql } from 'apollo-boost';

export const GET_VERIFICATION_LIST = gql`
  ${GET_VERIFICATION_FRAGMENT}
  query VerificationChecks(
    $verified: VerificationState
    $after: String
    $isAdmin: Boolean!
    $hasLocation: Boolean!
    $blockedByBusinessId: ID
    $inGroupBusinessId: ID
    $hasImage: Boolean
    $search: String
    $sortOrder: String
    $includeCount: Boolean = false
  ) {
    verification_checks(
      after: $after
      first: 10
      filter: { hasImage: $hasImage, verified: $verified, search: $search }
      order: $sortOrder
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount @include(if: $includeCount)
      nodes {
        ...VerificationCheckFragment
      }
    }
  }
`;

export const GET_PHOTO_VERIFICATION_LIST = gql`
  query Dancers($businessId: ID!, $isVerified: String) {
    dancers(businessId: $businessId, first: 25) {
      nodes {
        id
        name
        email
        first_image {
          id
          thumb
          is_verified
        }
        images(isVerified: $isVerified) {
          id
          thumb
          is_verified
          medium
          data_updated_at
        }
      }
    }
  }
`;
