import React, { useMemo } from 'react';
import * as PropTypes from 'prop-types';
import i18n from 'i18n';

import S from './notification-list.module.scss';
import { NotificationItem } from './notification-item';
import * as TimeHelpers from '../../../../../../../helpers/times';

const SHORT_TIME = TimeHelpers.TimePatterns.shortTime;
const DATE_FORMAT = TimeHelpers.TimePatterns.date;

const getAnnouncements = (announcements, timezone) => {
  let announcementMap = {};
  announcements.forEach(({ created_at, id, employee, title, message }) => {
    const START = TimeHelpers.createMoment(created_at, timezone);
    const DATE = START.format(DATE_FORMAT);

    if (!announcementMap[DATE]) announcementMap[DATE] = [];

    announcementMap[DATE] = [
      ...announcementMap[DATE],
      {
        id,
        time: START.format(SHORT_TIME),
        name: employee.name,
        message: {
          en: { title: title.en, message: message.en },
          es: { title: title.es, message: message.es },
        },
      },
    ];
  });
  return announcementMap;
};

const NotificationList = ({ timezone, list = [] }) => {
  const ANNOUNCEMENTS = useMemo(() => getAnnouncements(list, timezone), [list, timezone]);
  const ANNOUNCEMENT_ARRAY = useMemo(() => Object.keys(ANNOUNCEMENTS), [ANNOUNCEMENTS]);

  return (
    <ul className={S.notificationList}>
      {ANNOUNCEMENT_ARRAY.map((time) => (
        <div key={`announcement_dates_${time}`} className={S.notificationWrapper}>
          <div className={S.notificationDate}>
            {TimeHelpers.getFormattedJsTime(time, TimeHelpers.i18nTimePatterns.date[i18n.language])}
          </div>
          {ANNOUNCEMENTS[time].map((announcement) => (
            <NotificationItem key={announcement.id} {...announcement} />
          ))}
        </div>
      ))}
    </ul>
  );
};

NotificationList.displayName = 'NotificationList';
NotificationList.propTypes = {
  timezone: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.object),
};

export { NotificationList };
