import { gql } from 'apollo-boost';

export const GET_GETSTREAM_POSTS = gql`
  query GetstreamPosts(
    $first: Int
    $cursor: String
    $status: GetstreamPostStatusEnum!
    $commentReportsStatus: GetstreamCommentReportStatusEnum
  ) {
    getstream_posts(first: $first, after: $cursor, status: $status) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        id
        getstream_id
        text
        media {
          order
          media_type
          url
          width
          height
          external_file_id
        }
        time
        user {
          id
          name
          first_image {
            id
            thumb
            medium
            is_verified
          }
          getstream_user {
            getstream_id
          }
        }
        moderated_by {
          id
          name
          email
        }
        moderated_at
        comments_disabled
        status
        getstream_comment_reports(commentReportsStatus: $commentReportsStatus) {
          id
          getstream_id
          status
          moderated_at
          cache
          reasons
        }
      }
    }
  }
`;

export const GET_GETSTREAM_POST = gql`
  query GetstreamPost($id: ID!, $commentReportsStatus: GetstreamCommentReportStatusEnum) {
    getstream_post(id: $id) {
      id
      getstream_id
      text
      media {
        order
        media_type
        url
      }
      time
      user {
        id
        name
        first_image {
          thumb
          medium
          is_verified
        }
        getstream_user {
          getstream_id
        }
      }
      moderated_by {
        id
        name
        email
      }
      moderated_at
      status
      getstream_comment_reports(commentReportsStatus: $commentReportsStatus) {
        id
        getstream_id
        status
        moderated_at
        cache
        reasons
      }
    }
  }
`;
export const GET_GETSTREAM_COMMENT_REPORT = gql`
  query GetstreamCommentReport(
    $getstreamPostId: ID
    $first: Int!
    $cursor: String
    $status: GetstreamCommentReportStatusEnum!
  ) {
    getstream_comment_reports(getstreamPostId: $getstreamPostId, first: $first, after: $cursor, status: $status) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        id
        getstream_id
        status
        moderated_at
        cache
        reasons
      }
    }
  }
`;

export const GETSTREAM_ADMIN_POSTS = gql`
  query GetstreamAdminPosts($first: Int!, $cursor: String, $status: GetstreamAdminPostStatusEnum) {
    getstream_admin_posts(first: $first, after: $cursor, status: $status) {
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      nodes {
        id
        title
        text
        link {
          title
          url
        }
        publish_at
        status
        media {
          external_file_id
          has_sound
          height
          media_type
          order
          url
          width
        }
        getstream_post {
          getstream_id
          user {
            id
            name
            getstream_user {
              getstream_id
            }
          }
        }
      }
    }
  }
`;

export const GETSTREAM_ADMIN_POST = gql`
  query GetstreamAdminPost($id: ID!) {
    getstream_admin_post(id: $id) {
      id
      title
      text
      link {
        title
        url
      }
      publish_at
      media {
        external_file_id
        has_sound
        height
        media_type
        order
        url
        width
      }
    }
  }
`;
