import React, { useCallback, memo } from 'react';
import { FaTrash } from 'react-icons/fa';
import * as classNames from 'classnames';
import { useApolloClient } from '@apollo/react-hooks';

import S from './dancer-grid-item.module.scss';
import { DancerGridLocation } from './dancer-grid-location';
import { StagedCheckbox } from '../checkboxes/staged-checkbox';
import { DancerMenu } from '../dancer-action-menu/dancer-action-menu';
import { Image } from '../images/img-file';
import { useModal } from '../../global-hooks';
import { DANCER_GRID_FRAGMENT } from '../../../graphql/fragments/dancer-grid-fragment';
import { UI_MODALS } from '../../../helpers/enums';
import { useBusiness, useUser } from '../../../graphql/graph-hooks';
import DancerProfileImage from '../images/dancer-profile-image';

const DancerGridItem = memo(function DancerGridItem({
  dancerId,
  appId = null,
  isScalable = false,
  isSelected = false,
  hasOptions = false,
  hasLocation = false,
  selectDancer = () => null,
  onRemoveClick,
  removeDancer,
  businessId,
}) {
  const CLIENT = useApolloClient();
  const { admin: isAdmin } = useUser();
  const { id: currentBusinessId } = useBusiness();
  const targetBusinessId = businessId || currentBusinessId;
  const { name, first_image, email, locations, blocked_by, in_group } = CLIENT.readFragment({
    fragment: DANCER_GRID_FRAGMENT,
    id: `Dancer:${dancerId}`,
    variables: { isAdmin, hasLocation, blockedByBusinessId: targetBusinessId, inGroupBusinessId: targetBusinessId },
    fragmentName: 'DancerGridItem',
  });

  const { initModal } = useModal();
  const handleViewProfile = useCallback(
    () =>
      initModal(UI_MODALS.DANCER, {
        dancerId,
        removeDancer,
      }),
    [initModal, dancerId, removeDancer]
  );

  const handleSelectDancer = () => selectDancer(dancerId);

  const handleRemoveDancer = () => removeDancer(dancerId);

  return (
    <div className={isScalable ? S.dancerGridLg : S.dancerGridSm}>
      <div className={S.imgWrapper}>
        {selectDancer && (
          <div className={S.checkboxWrapper}>
            <StagedCheckbox stage={isSelected ? 'fill' : 'unchecked'} handleChange={handleSelectDancer} />
          </div>
        )}
        <DancerProfileImage
          alt={name}
          image={first_image}
          size={isScalable ? 'medium' : 'thumb'}
          classname={classNames({
            [S.selected]: isSelected,
            [S.canSelect]: selectDancer,
            [S.blocked]: blocked_by,
            [S.inGroup]: in_group,
          })}
          onClick={handleSelectDancer}
        />
        {onRemoveClick && <FaTrash className={S.delete} onClick={onRemoveClick} />}
      </div>
      <div>
        <div className={S.nameWrapper}>
          <div className={S.name} onClick={handleViewProfile}>
            {name}
          </div>
          {hasOptions && (
            <DancerMenu
              dancerId={dancerId}
              appId={appId}
              removeDancer={handleRemoveDancer}
              businessId={targetBusinessId}
            />
          )}
        </div>
        {blocked_by && <div>86&apos;d By: {blocked_by.name}</div>}
        {email && <div className={isScalable ? S.emailLg : S.emailSm}>{email}</div>}
        {hasLocation && <DancerGridLocation locations={locations} />}
      </div>
    </div>
  );
});

export { DancerGridItem };
