import React, { useCallback, useState, useRef, useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import S from './verification-list.module.scss';
import { VerificationItem } from './verification-item';
import { useVerificationInfiniteScroll } from './use-verification-scroll';
import { useModal } from '../../../global-hooks';
import { LoadingDiscoWithContainer } from '../../../common/loading/loading-disco';
import { useBusiness, useUser } from '../../../../graphql/graph-hooks';
import { GET_VERIFICATION_LIST } from '../../../../graphql/queries/verification-list';
import { UI_MODALS } from '../../../../helpers/enums';
import { logError } from '../../../../helpers/errors/bug-report';
import { useTranslation } from 'react-i18next';

const VerificationList = ({ stage, search = null, sortOrder, setItemCount, hasImage }) => {
  const { t } = useTranslation();
  let gridRef = useRef(null);
  const { admin } = useUser();
  const { initModal } = useModal();
  const { id: businessId } = useBusiness();
  const [verifications, setVerifications] = useState(null);

  const { loading, error, data, fetchMore } = useQuery(GET_VERIFICATION_LIST, {
    fetchPolicy: 'cache-and-network',
    variables: { verified: stage, after: '', isAdmin: admin, hasLocation: false, search: search, sortOrder: sortOrder, inGroupBusinessId: businessId, includeCount: true, hasImage: Boolean(hasImage) },
    onError: (err) => logError(err, 'GET_VERIFICATION_LIST', VerificationList.displayName),
  });

  const { isLoading, loadMore } = useVerificationInfiniteScroll({ stage, gridRef, fetchMore, data, hasImage });

  const handleOnSuccess = () => setItemCount(data.verification_checks.totalCount - 1);

  const handleSelection = useCallback(
    (id) => {
      initModal(UI_MODALS.VERIFICATIONS, { id, search, sortOrder, onSuccess: handleOnSuccess });
    },
    [initModal]
  );

  useEffect(() => {
    if (loading) {
      gridRef.current.scrollTo(0, 0);
      setVerifications([]);
      setItemCount(0);
    }
  }, [stage, search, sortOrder, hasImage]);

  useEffect(() => {
    if (data?.verification_checks?.nodes) {
      setVerifications(data.verification_checks.nodes);
      setItemCount(data.verification_checks.totalCount);
    }

  }, [data?.verification_checks]);



  if (error) return <h1 className={S.errorPage}>{t('errors.generic')}</h1>;
  if (!loading && verifications && verifications.length === 0) {
    return <h1 className={S.h1Heading}>{t('verificationPage.empty_list')}</h1>;
  }

  return (
    <div ref={gridRef} onScroll={loadMore} className={S.verificationList}>
      <>
        {verifications?.map((v) => (
          <VerificationItem key={v.id} onSelection={handleSelection} {...v} sortOrder={sortOrder} search={search} />
        ))}

        {(isLoading || loading) && (
          <div className={S.verificationLoadingRow}>
            <LoadingDiscoWithContainer />
          </div>
        )}
      </>
    </div>
  );
};

VerificationList.displayName = 'VerificationListPresenter';
VerificationList.propTypes = {
  stage: PropTypes.string.isRequired,
  search: PropTypes.string,
  sortOrder: PropTypes.string.isRequired,
  hasImage: PropTypes.bool.isRequired,
  setItemCount: PropTypes.func.isRequired,
};

export { VerificationList };
