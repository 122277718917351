import * as React from 'react';

import DancerProfileImage from './images/dancer-profile-image';

import S from './group-grid.module.scss';

const GroupGrid = ({ dancers, groupId, count }) => {
  return (
    <div className={count <= 4 ? S.smGroupGrid : S.lgGroupGrid}>
      {dancers.slice(0, 9).map((dancer, i) => {
        return (
          <div key={`GROUP_${groupId}_DANCER_${dancer.id}`} className={S.groupImgWrapper}>
            <DancerProfileImage image={dancer.first_image} size={'thumb'} alt={dancer.name} />
            {i === 8 && count > 9 && <div>{count}</div>}
          </div>
        );
      })}
    </div>
  );
};

export { GroupGrid };
