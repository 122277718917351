import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as Icons from 'react-icons/all';

import { RouteCreator } from 'components/routes/route-constants';

import { useBusiness } from 'graphql/graph-hooks';
import * as TimeHelpers from 'helpers/times';
import { BOOKING_STATUS } from 'helpers/enums';
import { InfoProps } from '../types';

const FORMAT = TimeHelpers.TimePatterns.dateShortFormatted;

const Info = ({ status, acceptedBy, shift, isInvite }: InfoProps) => {
  const { t } = useTranslation();
  const { timezone } = useBusiness();
  const count =
    shift.accepted_shift_applications_count +
    shift.checked_in_shift_applications_count +
    shift.checked_out_shift_applications_count;

  return (
    <div className='flex flex-col justify-center mr-4'>
      <div>
        <span className='float-right -mt-1'>
          {isInvite && <Icons.GiMegaphone size='20' title={t('common.invited')} />}
        </span>
        <Link
          to={RouteCreator.applicationRequests(shift.id, shift.is_audition)}
          className='text-business-purple decoration-0'
        >
          {t('common.view_booking')}
        </Link>
      </div>
      <div className='flex justify-between text-sm'>
        <label className='mb-0'>{t('common.slots')}:</label>
        <span className='text-gray-400'>
          {count}/{shift.slots}
        </span>
        {shift.hardcapped ? <span className='font-bold'>{t('common.strict_limit')}</span> : ''}
      </div>
      {shift.standby_shift_applications_count > 0 && (
        <div className='flex justify-between text-sm gap-2'>
          <label className='mb-0'>{t('common.standby')}:</label>
          <div className='text-gray-400'>{shift.standby_shift_applications_count}</div>
        </div>
      )}
      <div className='flex justify-between text-sm gap-2'>
        <label className='mb-0'>{t('common.start')}:</label>
        <div className='text-gray-400'>{TimeHelpers.createMoment(shift.start_time, timezone).format(FORMAT)}</div>
      </div>
      <div className='flex justify-between text-sm gap-2'>
        <label className='mb-0'>{t('common.end')}:</label>
        <div className='text-gray-400'>{TimeHelpers.createMoment(shift.end_time, timezone).format(FORMAT)}</div>
      </div>
    </div>
  );
};

export default Info;
