import { gql } from 'apollo-boost';
export const GET_PROFILE_PICTURES_LIST = gql`
  query ProfilePictures(
    $isVerified: Boolean
    $dancer: Boolean!
    $includeIncomplete: Boolean
    $includeRejected: Boolean
    $sortOrder: String
    $search: String
    $first: Int
    $after: String
  ) {
    profile_pictures(
      isVerified: $isVerified
      dancer: $dancer
      includeIncomplete: $includeIncomplete
      includeRejected: $includeRejected
      order: $sortOrder
      search: $search
      first: $first
      after: $after
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      nodes {
        id
        thumb
        medium
        is_verified
        rejection_text
        data_updated_at
        moderated_at
        moderated_by {
          name
        }
        position
        user {
          id
          name
          email
          dancer
          verification_check {
            id
            first_image {
              id
              thumb
              medium
              is_verified
            }
          }
        }
      }
    }
  }
`;
