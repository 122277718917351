import { Link } from 'react-router-dom';
import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import S from './nav-user-area.module.scss';
import { Image } from '../common/images/img-file';
import { RouteConstants, RouteCreator } from '../routes/route-constants';
import { useBusiness, useUser } from '../../graphql/graph-hooks';
import DancerProfileImage from 'components/common/images/dancer-profile-image';

const NavUserArea = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { id: businessId } = useBusiness();
  const { t } = useTranslation();
  const { id: userId, name, first_image, roles, admin } = useUser();

  const ROLE = useMemo(() => (admin ? 'admin' : roles?.[businessId]?.slug ?? ''), [admin, businessId, roles]);

  return (
    <div className={S.userAreaWrapper} onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)}>
      <div className={S.userAreaDropdown}>
        <div className={S.nameArea}>
          <span /> {t('common.hi')}, {name}
        </div>
        <div className={S.roleArea}>{t(`employeePage.roles.${ROLE}`)}</div>
        {isOpen && (
          <ul>
            <Link to={RouteConstants.logOut}>{t('common.logout')}</Link>
            <Link to={RouteCreator.userForm(userId)}>{t('common.profile')}</Link>
          </ul>
        )}
      </div>
      <DancerProfileImage alt={name} image={first_image} size='thumb' />
    </div>
  );
};

NavUserArea.displayName = 'UserAreaPresenter';

export { NavUserArea };
