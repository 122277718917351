import { useState, FC, MouseEvent } from 'react';
import { useUser } from 'graphql/graph-hooks';

import defaultDancerPhoto from '../../../assets/user-placeholder-mask.png';

import type { Image as ImageType } from 'types';

import S from './dancer-profile-image.module.scss';
import classNames from 'classnames';

interface Props {
  alt?: string;
  image: ImageType | null;
  size: 'thumb' | 'medium' | 'large';
  styles?: { [key: string]: string | number };
  title?: string;
  classname?: string;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
}

const DancerProfileImage: FC<Props> = ({ alt, image, size, styles, title, classname, onClick }) => {
  const { admin: isAdmin } = useUser();
  const [showPlaceholder, setShowPlaceholder] = useState(image?.is_verified === false && !isAdmin ? true : false);

  const handleOnError = () => {
    setShowPlaceholder(true);
  };

  return showPlaceholder ? (
    <img alt={alt} title={title} className={classname} style={styles} src={defaultDancerPhoto} onClick={onClick} />
  ) : (
    <div className={classNames([S.imageWrapper, classname])}>
      {image?.is_verified === false && <div className={S.rejectedOverlay}></div>}
      <img
        alt={alt}
        title={title}
        className={classname}
        style={styles}
        src={(image && image[size]) || defaultDancerPhoto}
        onClick={onClick}
        onError={handleOnError}
      />
    </div>
  );
};

export default DancerProfileImage;
