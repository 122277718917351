import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import S from './verification-item.module.scss';
import { Image } from '../../../common/images/img-file';
import ImgMissing from 'components/common/images/img-missing';
import moment from 'moment';
import DancerProfileImage from 'components/common/images/dancer-profile-image';

const VerificationItem = ({ id, dancer, created_at, moderated_at, updated_at, verified, sentence, onSelection, rejection_text, first_image, moderated_by }) => {
  const { t } = useTranslation();
  if (dancer === null) {
    return null;
  }

  return (
    <div className='flex pointer-cursor hover:bg-purple-900 hover:cursor-pointer mb-2' onClick={() => onSelection(id)}>
      <div className='w-30 p-2'>
        <DancerProfileImage alt={dancer.name} image={first_image} size='medium' classname='w-28 h-28 rounded-md' />
      </div>
      <div className='w-96 p-2'>
        <div className={S.dataRow}>
          <label>{t('verificationPage.invited_by')}:</label>
          <span className={S.sentence}>
            {dancer.registration_code?.user?.name}
            {dancer.registration_code?.business?.name ? ` - ${dancer.registration_code.business.name}` : ''}
          </span>
        </div>

        <div className={S.dataRow}>
          <label>{t('verificationPage.code')}:</label>
          <span className={S.code}>{dancer.registration_code?.code}</span>
        </div>

        <div className={S.dataRow}>
          <label>{t('verificationPage.sentence.title')}:</label>
          <span className={S.sentence}>{t(`verificationPage.sentence.${sentence}`)}</span>
        </div>
      </div>
      <div className='w-80 p-2'>
        <div className={S.dataRow}>
          <label>{t('common.name')}:</label>
          <span className={S.name}>{dancer.name}</span>
        </div>

        <div className={S.dataRow}>
          <label>{t('common.email')}:</label>
          <span className={S.sentence}>{dancer.email}</span>
        </div>
        <div className={S.dataRow}>
          <label>{t('common.submitted')}:</label>
          <span className={S.sentence}>{`${moment(created_at).tz('America/Chicago').format('MM/DD/YYYY, h:mm A')} CST`}</span>
        </div>
      </div>
      {(verified === 'ACCEPTED' || verified === 'REJECTED') && (
        <div className='w-80 p-2'>
          {moderated_at && (
            <div className={S.dataRow}>
              <label>{t('common.moderated_at')}:</label>
              <span className={S.sentence}>{`${moment(moderated_at || updated_at).tz('America/Chicago').format('MM/DD/YYYY, h:mm A')} CST`}</span>
            </div>
          )}
          {moderated_by && (
            <div className={S.dataRow}>
              <label>{t('common.moderated_by')}:</label>
              <span className={S.sentence}>{moderated_by.name}</span>
            </div>
          )}
          {rejection_text && (
            <div className={S.dataRow}>
              <label>{t('verificationPage.outcome')}:</label>
              <span className={S.sentence}>
                <span className={S.type}>{t('verificationPage.rejected')} - </span>
                <span className={S.rejection}>{rejection_text}</span>
              </span>
            </div>
          )}
        </div>
      )}

      <div className='w-2/5 flex flex-row space-between p-2 overflow-x-auto'>

        {dancer.images?.length ? dancer.images.map((image) => (
          image.medium ?
            <DancerProfileImage
              key={image.id}
              image={image}
              size='medium'
              alt={image.id}
              classname='mr-4 w-28 h-28 inline-block rounded-md'
            /> : (
              <ImgMissing text={t('common.missing').toUpperCase()} classNames='w-28 h-28' />
            )
        )) : <ImgMissing text={t('common.missing').toUpperCase()} classNames='w-28 h-28' />}
      </div>
    </div>
  );
};

VerificationItem.displayName = 'VerificationItemPresenter';
VerificationItem.propTypes = {
  rejection_text: PropTypes.string,
  id: PropTypes.string.isRequired,
  dancer: PropTypes.object.isRequired,
  sentence: PropTypes.string.isRequired,
  onSelection: PropTypes.func.isRequired,
  first_image: PropTypes.object.isRequired,
};

VerificationItem.defaultProps = {
  rejection_text: null,
};

export { VerificationItem };
