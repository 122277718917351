import { DANCER_GRID_FRAGMENT } from '../fragments/dancer-grid-fragment';
import { gql } from 'apollo-boost';

export const GROUP_ANNOUNCEMENTS = gql`
  query GroupAnnouncements($groupId: ID!, $first: Int, $last: Int, $before: String, $after: String) {
    group_announcements(groupId: $groupId, first: $first, last: $last, before: $before, after: $after) {
      nodes {
        id
        title
        message
        created_at
        employee {
          id
          name
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;

export const GROUP_PAGE_GQL = gql`
  ${DANCER_GRID_FRAGMENT}
  query GroupAndGroupAnnouncementsAndPreapprovedShifts(
    $groupId: ID!
    $isAdmin: Boolean!
    $hasLocation: Boolean!
    $blockedByBusinessId: ID
    $inGroupBusinessId: ID
  ) {
    preapproved_shifts(groupId: $groupId) {
      non_recurring_shifts {
        id
        start_time
        end_time
        shift_type
      }
      recurring_shifts {
        id
        start_time
        end_time
        shift_type
      }
    }

    group(id: $groupId) {
      id
      name
      number_of_dancers
      default
      dancers {
        ...DancerGridItem
        favorite_clubs {
          id
        }
        locations {
          id
          latitude
          longitude
          city
          state
          google_places_id
        }
      }
      business {
        id
        timezone
        longitude
        latitude
      }
    }
  }
`;
