import { toast } from 'react-toastify';
import * as PropTypes from 'prop-types';
import { useMutation } from '@apollo/react-hooks';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { logError } from '../../../helpers/errors/bug-report';
import { useTranslation } from 'react-i18next';

import S from './modal-verification-form.module.scss';
import { FormSelectField } from '../../common/form/form-select-field';
import { RadioInput } from '../../common/radio-buttons/radio-input';
import { useUser, useBusiness } from '../../../graphql/graph-hooks';
import { UPDATE_VERIFICATION_GQL } from '../../../graphql/mutations/verification-update';
import { GET_VERIFICATION_LIST } from '../../../graphql/queries/verification-list';
import { VERIFICATION_STAGES } from '../../../helpers/enums';
import { CREATE_CONVERSATION } from 'graphql/mutations/conversation-create';
import { useHistory } from 'react-router';
import { useModal } from 'components/global-hooks';
import { RouteConstants } from 'components/routes/route-constants';

const ModalVerificationForm = ({
  id,
  dancer,
  verified,
  rejection_text,
  handleClose,
  handleOnSuccess,
  search,
  sortOrder,
  first_image: verificationImage,
}) => {
  const { t } = useTranslation();
  const { admin } = useUser();
  const HISTORY = useHistory();
  const { id: businessId } = useBusiness();
  const { closeModal } = useModal();

  const REJECTION_TEXTS = [
    t('modals.verification.rejection_reason.verification_picture'),
    t('modals.verification.rejection_reason.profile_picture'),
    t('modals.verification.rejection_reason.unable_to_verify'),
    t('modals.verification.rejection_reason.time_expired'),
  ];
  const REJECTION_REASONS = ['INVALID_VERIFICATION', 'INVALID_PROFILE_PICTURE', 'UNABLE_TO_VERIFY', 'EXPIRED'];

  const SELECTED = useMemo(() => REJECTION_TEXTS.indexOf(rejection_text), [rejection_text]);

  const [reasonIndex, setReasonIndex] = useState(SELECTED !== -1 ? SELECTED.toString() : '');
  const [outcome, setOutcome] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const handleOutcomeChange = useCallback((e) => setOutcome(e.target.value), [setOutcome]);
  const handleReasonChange = useCallback((e) => setReasonIndex(e.target.value), [setReasonIndex]);
  const [submitVerification, { loading }] = useMutation(UPDATE_VERIFICATION_GQL, {
    variables: {
      id: Number(id),
      verified: outcome === VERIFICATION_STAGES.ACCEPTED,
      rejectionText: outcome === VERIFICATION_STAGES.ACCEPTED ? '' : REJECTION_TEXTS[Number(reasonIndex)],
      rejectionReason: outcome === VERIFICATION_STAGES.ACCEPTED ? null : REJECTION_REASONS[Number(reasonIndex)],
      rejectedPictureIds: [dancer.first_image?.id],
    },
    onError: (err) => {
      logError(err, UPDATE_VERIFICATION_GQL, ModalVerificationForm.displayName);
      setErrorMsg(
        `${t('errors.server.500')}: ${
          err.message == 'GraphQL error: CHECK_ALREADY_REJECTED'
            ? t('modals.verification.check_already_rejected')
            : t('modals.verification.failure')
        }`
      );
    },
    update: (
      cache,
      {
        data: {
          update_verification_check: { verification_check },
        },
      }
    ) => {
      if (verified !== verification_check.verified) {
        const VERIFICATION_CHECKS_VARIABLES = {
          verified,
          after: '',
          isAdmin: admin,
          hasLocation: false,
          search,
          sortOrder,
          inGroupBusinessId: businessId,
        };

        const { verification_checks } = cache.readQuery({
          query: GET_VERIFICATION_LIST,
          variables: VERIFICATION_CHECKS_VARIABLES,
        });

        cache.writeQuery({
          query: GET_VERIFICATION_LIST,
          variables: VERIFICATION_CHECKS_VARIABLES,
          data: {
            verification_checks: {
              ...verification_checks,
              nodes: verification_checks.nodes.filter((check) => check.id !== id),
            },
          },
        });
      }
    },
    onCompleted: () => {
      handleOnSuccess();
      toast.success(t('modals.verification.success'));
      handleClose();
    },
  });

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (outcome === VERIFICATION_STAGES.PENDING) setErrorMsg(t('modals.verification.select_verification_option'));
      else if (outcome === VERIFICATION_STAGES.REJECTED && !reasonIndex)
        setErrorMsg(t('modals.verification.select_reason'));
      else await submitVerification();
    },
    [submitVerification, outcome, reasonIndex]
  );

  const [createConvo] = useMutation(CREATE_CONVERSATION, {
    variables: {
      businessId: businessId,
      dancerId: dancer.id,
      isAdmin: admin,
      blockedByBusinessId: businessId,
      inGroupBusinessId: businessId,
    },
    onCompleted: ({ conversation_create_or_add: { conversation } }) => {
      HISTORY.push({
        pathname: RouteConstants[admin ? 'adminConversations' : 'conversations'],
        search: `cid=${conversation.id}`,
      });
      closeModal();
    },
    onError: (err) => {
      logError(err, 'CREATE_CONVERSATION', 'useMenuActions');
      toast.error(`${t('errors.server.500')}: ${t('modals.verification.conversation.error')}`);
    },
  });

  useEffect(() => setErrorMsg(''), [outcome, setReasonIndex]);

  const options = [{ value: '', label: t('common.select_option') }];
  if (verified !== VERIFICATION_STAGES.ACCEPTED) {
    options.push({ value: VERIFICATION_STAGES.ACCEPTED, label: t('modals.verification.accept') });
  }
  options.push({ value: VERIFICATION_STAGES.REJECTED, label: t('modals.verification.reject') });

  return (
    <form onSubmit={onSubmit} className={S.verificationForm}>
      <div className={S.dancerInfo}>
        <h3>{dancer.name}</h3>
        <p title={dancer.email}>{dancer.email}</p>
        <p>
          {t('modals.verification.code')}: {dancer?.registration_code?.code}
        </p>

        <p>
          {t('modals.verification.invited_by')}: {dancer.registration_code?.user?.name}
        </p>
        {dancer.registration_code?.business?.name && <p>{dancer.registration_code.business.name}</p>}
      </div>
      {verified !== VERIFICATION_STAGES.REJECTED && (
        <FormSelectField
          label={t('modals.verification.outcome')}
          id='modal_verification_outcome'
          name='outcome'
          handleChange={handleOutcomeChange}
          value={outcome}
          isDisabled={loading || !verificationImage}
          options={options}
        />
      )}

      <ul className={S.reasonList}>
        {outcome === VERIFICATION_STAGES.REJECTED &&
          REJECTION_TEXTS.map((text, i) => (
            <li key={`reject_reason_${i}`}>
              <RadioInput
                id={`verification_reason_${i}`}
                name='verification_reason'
                label={text}
                value={i}
                isDisabled={loading}
                isChecked={reasonIndex === i.toString()}
                handleChange={handleReasonChange}
              />
            </li>
          ))}
      </ul>
      <p className={S.errorMessage}>{errorMsg}</p>
      {verified !== VERIFICATION_STAGES.REJECTED && (
        <button
          className={S.submitBtn}
          disabled={loading || !outcome || (outcome === VERIFICATION_STAGES.REJECTED && !reasonIndex)}
        >
          {t('common.submit')}
        </button>
      )}

      <button
        className={S.submitBtn}
        disabled={loading}
        onClick={(e) => {
          e.preventDefault();
          createConvo();
        }}
      >
        {t('modals.verification.message')}
      </button>
    </form>
  );
};

ModalVerificationForm.displayName = 'ModalVerificationForm';
ModalVerificationForm.propTypes = {
  rejection_text: PropTypes.string,
  id: PropTypes.string.isRequired,
  dancer: PropTypes.object.isRequired,
  verified: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  search: PropTypes.string,
  sortOrder: PropTypes.string,
};

export { ModalVerificationForm };
