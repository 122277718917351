import { FC, ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const PageContainer: FC<Props> = ({ children }) => {
  return <div className='min-h-full mt-16 mx-10'>{children}</div>;
};

export default PageContainer;
