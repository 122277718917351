import moment from 'moment';
import type { ConversationTypes } from 'types/conversation';

export const sortAndFilterConversations = (conversations: ConversationTypes[], filter: string): ConversationTypes[] =>
  conversations
    .filter(({ user }) => user)
    .filter(({ user: { name } }) => name.toUpperCase().includes(filter.toUpperCase()))
    .sort((a, b) => {
      const MOMENT_A = moment(a.updated_at);
      const MOMENT_B = moment(b.updated_at);

      if (MOMENT_A.isBefore(MOMENT_B)) return 1;
      else if (MOMENT_A.isAfter(MOMENT_B)) return -1;
      else return 0;
    });
