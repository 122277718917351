import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import S from './invites-previously-invited.module.scss';
import DancerProfileImage from 'components/common/images/dancer-profile-image';

const PreviouslyInvited = ({ dancers }) => {
  const { t } = useTranslation();
  return (
    <div className={S.previouslyInvitedWrapper}>
      {dancers.length <= 7 ? (
        dancers.map(({ dancer: { id, name, first_image } }) => (
          <DancerProfileImage key={id} alt={name} title={name} image={first_image} size='thumb' />
        ))
      ) : (
        <>{`${dancers.length} ${t('modals.invite.dancers_previously_invited')}`}</>
      )}
    </div>
  );
};

PreviouslyInvited.displayName = 'PreviouslyInvited';
PreviouslyInvited.propTypes = {
  dancers: PropTypes.array.isRequired,
};

export { PreviouslyInvited };
