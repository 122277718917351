import React, { useCallback } from 'react';
import { FaCommentAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/react-hooks';

import { GROUP_ANNOUNCEMENTS } from 'graphql/queries/group-detail-page-query';
import { useModal } from '../../../../../global-hooks';
import { UI_MODALS } from '../../../../../../helpers/enums';
import S from '../../../../../../sass/buttons/modern-btn.module.scss';

const BtnGroupBlast = ({ groupId }) => {
  const { t } = useTranslation();
  const { initModal, closeModal } = useModal();
  const client = useApolloClient();

  const handleOnSuccess = () => {
    client.reFetchObservableQueries([{ query: GROUP_ANNOUNCEMENTS, variables: { groupId, first: 10 } }]);
    closeModal();
  };

  const handleBlast = useCallback(
    () => initModal(UI_MODALS.BLAST_NOTIFY, { id: groupId, onSuccess: handleOnSuccess }),
    [initModal, groupId]
  );

  return (
    <button onClick={handleBlast} className={S.modernBtn}>
      <FaCommentAlt /> {t('groupsPage.send_announcement')}
    </button>
  );
};

export { BtnGroupBlast };
