import * as React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import * as PropTypes from 'prop-types';

import S from './msg-item.module.scss';
import { TimePatterns } from 'helpers/times';
import type { MsgSender } from 'types/conversation';

type MsgItemProps = {
  targetId: string;
  body: string;
  firstMsg: boolean;
  showTime: boolean;
  created_at: string;
  isFirstUnRead: boolean;
  sender?: MsgSender;
  showNewMsgIndicator: boolean;
  read: boolean;
};

const MsgItem: React.FC<MsgItemProps> = ({
  targetId,
  body,
  firstMsg,
  showTime,
  created_at,
  isFirstUnRead,
  sender,
  showNewMsgIndicator,
  read,
}): React.ReactElement => {
  const { t, i18n } = useTranslation();
  const IS_TARGET_USER = targetId === sender?.id;
  const senderName = sender?.name || `[${i18n.t('common.deleted').toUpperCase()}]`;

  return (
    <>
      {showNewMsgIndicator && isFirstUnRead && (
        <div className={S.newLine}>
          <span />
          <span>{t('common.new')}</span>
        </div>
      )}
      <div className={IS_TARGET_USER ? S.dancerMsgWrapper : S.bizMsgWrapper}>
        <div
          className={classNames(S.message, {
            [S.dancer]: IS_TARGET_USER,
            [S.first]: firstMsg || isFirstUnRead,
            [S.last]: showTime,
          })}
        >
          {body}
        </div>
        {showTime && (
          <div className={IS_TARGET_USER ? S.dancerDate : S.bizDate}>
            <div className='flex justify-end'>
              <div className='mr-2'>
                {moment(created_at).locale(i18n.language).format(TimePatterns.chatTime)}
                {!IS_TARGET_USER && ` - ${senderName} `}
              </div>
              {targetId !== sender?.id && (read ? '✔✔' : '✔')}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

MsgItem.propTypes = {
  targetId: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  firstMsg: PropTypes.bool.isRequired,
  showTime: PropTypes.bool.isRequired,
  created_at: PropTypes.string.isRequired,
  isFirstUnRead: PropTypes.bool.isRequired,
  sender: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
};

export { MsgItem };
