import { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import { NotificationList } from './notification-list';
import { useTranslation } from 'react-i18next';

import { GROUP_ANNOUNCEMENTS } from 'graphql/queries/group-detail-page-query';

import S from './notification-wrapper.module.scss';
import LoadMoreButton from 'components/common/buttons/load-more-btn/load-more-btn';

const NotificationWrapper = ({ groupId, timezone }) => {
  const { t } = useTranslation();
  const [pageInfo, setPageInfo] = useState({ hasNextPage: false, after: null });
  const variables = {
    groupId,
    first: 10,
  };

  const { loading, error, data, fetchMore } = useQuery(GROUP_ANNOUNCEMENTS, {
    fetchPolicy: 'cache-and-network',
    variables,
    onCompleted: ({ group_announcements }) => setPageInfo(group_announcements.pageInfo),
    onerror: (error) => console.log('GB|ERROR', error),
  });

  const getMore = async () =>
    await fetchMore({
      variables: { ...variables, after: pageInfo.endCursor },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return previousResult;
        }

        setPageInfo(fetchMoreResult.group_announcements.pageInfo);

        return {
          group_announcements: {
            ...fetchMoreResult.group_announcements,
            nodes: [...previousResult.group_announcements.nodes, ...fetchMoreResult.group_announcements.nodes],
          },
        };
      },
    });

  if (error) return <h1 className={S.errorPage}>{t('errors.generic')}</h1>;

  return !loading && data?.group_announcements?.nodes.length === 0 ? (
    <h3 className={S.noBookingWrapper}>{t('groupsPage.no_announcements')}</h3>
  ) : (
    <div>
      <NotificationList list={data?.group_announcements.nodes} timezone={timezone} />
      {pageInfo.hasNextPage && (
        <div className={S.loadMorebtnWrapper}>
          <LoadMoreButton onClick={getMore} isLoading={loading} />
        </div>
      )}
    </div>
  );
};

export { NotificationWrapper };
