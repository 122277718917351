import { gql } from 'apollo-boost';

// Figure out how to add fragment above to this query
export const GET_BLAST_GQL = gql`
  query BlastAnnouncements {
    blast_announcements {
      id
      blast_type
      title
      message
      created_at
      user {
        id
        name
        first_image {
          id
          thumb
        }
      }
    }
  }
`;

export const BLAST_ANNOUNCEMENTS_PAGINATED = gql`
  query BlastAnnouncementsPaginated($first: Int, $after: String, $blastType: BlastTypeEnum = null) {
    blast_announcements_paginated(first: $first, after: $after, blastType: $blastType) {
      nodes {
        id
        blast_type
        title
        message
        target_url
        created_at
        user {
          id
          name
          first_image {
            id
            thumb
          }
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
