import React from 'react';
import PropTypes from 'prop-types';

import SL from '../../../../sass/layout-styles.module.scss';
import S from './club-form-page.module.scss';
import { ClubForm } from './club-form';
import { PageClubFormProvider } from './club-form-context';
import classNames from 'classnames';

const ClubFormPage = ({ isNewForm }) => {
  return (
    <PageClubFormProvider>
      <div className={classNames([SL.pageWrapper, S.scrollable])}>
        <ClubForm isNewForm={isNewForm} />
      </div>
    </PageClubFormProvider>
  );
};

ClubFormPage.displayName = 'ClubFormPage';

ClubFormPage.propTypes = {
  isNewForm: PropTypes.bool,
};

ClubFormPage.defaultProps = {
  isNewForm: false,
};

export { ClubFormPage };
