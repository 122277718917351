import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useQuery } from '@apollo/react-hooks';
import { logError } from '../../../../../helpers/errors/bug-report';

import S from './group-details-page.module.scss';
import { GroupDancerList } from './group-list';
import { GroupPageAside } from './group-aside/group-aside';
import { GroupHeader } from './group-header/group-header';
import { LoadingDiscoWithContainer } from '../../../../common/loading/loading-disco';
import { useBusiness, useUser } from '../../../../../graphql/graph-hooks';
import { GROUP_PAGE_GQL } from '../../../../../graphql/queries/group-detail-page-query';
import { useTranslation } from 'react-i18next';

const GroupDetailsPage = () => {
  const { t } = useTranslation();
  const { admin: isAdmin } = useUser();
  const { groupId } = useParams();
  const { id: business_id } = useBusiness();

  const { loading, error, data } = useQuery(GROUP_PAGE_GQL, {
    fetchPolicy: 'network-only',
    variables: {
      groupId,
      isAdmin,
      hasLocation: false,
      blockedByBusinessId: business_id,
      inGroupBusinessId: business_id,
    },
    onError: (err) => logError(err, GROUP_PAGE_GQL, GroupDetailsPage.displayName),
  });

  if (loading) return <LoadingDiscoWithContainer />;
  if (error) return <h1 className={S.errorPage}>{t('errors.generic')}</h1>;

  return (
    <div className={S.pageWrapper}>
      <GroupHeader
        name={data.group.name}
        groupId={groupId}
        dancers={data.group.dancers}
        isDefaultGroup={data.group.default}
      />

      <div className={S.groupDetailBottom}>
        <GroupDancerList isAdmin={isAdmin} groupId={groupId} dancerList={Object.values(data.group.dancers)} />
        <GroupPageAside timezone={data.group.business.timezone} groupId={groupId} />
      </div>
    </div>
  );
};

GroupDetailsPage.displayName = 'GroupDetailsPage';

export { GroupDetailsPage };
