import * as React from 'react';
import { useTranslation } from 'react-i18next';

import S from './message-area.module.scss';

const TextMessage = ({ lang, title, message, hasLabel }) => (
  <div className={S.textWrapper}>
    {hasLabel && <span>{lang}:</span>}
    <div className={S.msgTitle}>{title}</div>
    <div>{message}</div>
  </div>
);

const MessageArea = ({ message, title, targetUrl }) => {
  const { t } = useTranslation();
  const HAS_SPANISH = !!(title.es || message.es);

  return (
    <div className={S.msgWrapper}>
      <TextMessage lang='English' message={message.en} title={title.en} hasLabel={HAS_SPANISH} />
      {HAS_SPANISH && <TextMessage lang='Spanish' message={message.es} title={title.es} hasLabel />}

      {targetUrl && (
        <div className={S.target}>
          {t('modals.message.targetUrl')}: <a href={targetUrl}>{targetUrl}</a>
        </div>
      )}
    </div>
  );
};

export { MessageArea };
