import { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import S from './notification-list.module.scss';
import { AdminNotificationItem } from './admin-notification-item';
import { LoadingDiscoWithContainer } from '../../../common/loading/loading-disco';
import { logError } from '../../../../helpers/errors/bug-report';
import { BLAST_ANNOUNCEMENTS_PAGINATED } from '../../../../graphql/queries/blast-get-query';
import classNames from 'classnames';
import LoadMoreButton from 'components/common/buttons/load-more-btn/load-more-btn';

const TYPES = {
  ALL: 'all_users',
  DANCERS: 'all_dancers',
  WORKERS: 'all_workers',
  EMPLOYEES: 'all_employees_by_club',
};

const NotificationList = ({ blastType }) => {
  const { t } = useTranslation();
  const [pageInfo, setPageInfo] = useState({ hasNextPage: false, endCursor: null });
  const [fetchingMore, setFetchingMore] = useState(false);
  const { loading, error, data, fetchMore } = useQuery(BLAST_ANNOUNCEMENTS_PAGINATED, {
    fetchPolicy: 'cache-and-network',
    variables: {
      first: 10,
      blastType,
    },
    onCompleted: ({ blast_announcements_paginated }) => {
      if (!fetchingMore) {
        setPageInfo(blast_announcements_paginated.pageInfo);
      }
    },
    onError: (err) => logError(err, 'GET_BLAST_GQL', NotificationList.displayName),
  });

  const getMore = async () => {
    setFetchingMore(true);
    try {
      await fetchMore({
        variables: { after: pageInfo.endCursor },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            return previousResult;
          }

          setPageInfo(fetchMoreResult.blast_announcements_paginated.pageInfo);

          return {
            blast_announcements_paginated: {
              ...fetchMoreResult.blast_announcements_paginated,
              nodes: [
                ...previousResult.blast_announcements_paginated.nodes,
                ...fetchMoreResult.blast_announcements_paginated.nodes,
              ],
            },
          };
        },
      });
    } catch (error) {
      console.log('Fetching more error', error);
    } finally {
      setFetchingMore(false);
    }
  };

  if (!fetchingMore && loading) return <LoadingDiscoWithContainer />;
  if (error) return <h1 className={S.errorPage}>{t('errors.generic')}</h1>;

  return data?.blast_announcements_paginated?.nodes ? (
    <>
      <div className={S.notificationTableHeader}>
        <div>{t('common.details')}</div>
        <div>{t('notificationsPage.blast_message')}</div>
      </div>
      <div className={classNames([S.scrollBar, S.listWrapper])}>
        {data.blast_announcements_paginated.nodes.map((announcement) => (
          <AdminNotificationItem key={`notification_${announcement.id}`} {...announcement} />
        ))}
        {pageInfo.hasNextPage && (
          <div className={S.loadMorebtnWrapper}>
            <LoadMoreButton onClick={getMore} isLoading={fetchingMore} />
          </div>
        )}
      </div>
    </>
  ) : null;
};

export { NotificationList };
