import * as React from 'react';
import * as PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';

import S from './admin-notification-item.module.scss';
import { MessageArea } from '../message-area';
import { Image } from '../../../common/images/img-file';
import * as TimeHelpers from '../../../../helpers/times';

const AdminNotificationItem = ({ created_at, message, user, blast_type, title, target_url }) => {
  const { t } = useTranslation();
  return (
    <div className={S.adminNotificationItem}>
      <Image alt='user profile' src={user?.first_image?.thumb} fallback='USER' />

      <div className={S.meta}>
        <div className={S.audience}>{blast_type.replace('_', ' ')}</div>
        <p className={S.author}>
          {t('notificationsPage.sent_by')} <span>{user.name}</span>
        </p>
        <p className={S.time}>
          {TimeHelpers.getFormattedJsTime(created_at, TimeHelpers.i18nTimePatterns.dateTime[i18n.language])}
        </p>
      </div>
      <MessageArea title={title} message={message} targetUrl={target_url} />
    </div>
  );
};

const MsgShape = PropTypes.shape({
  en: PropTypes.string.isRequired,
  es: PropTypes.string,
});

AdminNotificationItem.displayName = 'NotificationItemPresenter';
AdminNotificationItem.propTypes = {
  created_at: PropTypes.string.isRequired,
  message: MsgShape.isRequired,
  title: MsgShape.isRequired,
  blast_type: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    first_image: PropTypes.shape({
      thumb: PropTypes.string,
    }),
  }),
};

export { AdminNotificationItem };
