import moment from 'moment';
import i18n from 'i18n';

export const TimePatterns = {
  shortDateFormatted: 'MMM D, YYYY',
  bookingFormat: 'dddd, MMMM D',
  dateFormatted: 'MMM DD, YYYY hh:mma',
  dateShortFormatted: 'MMM DD, hh:mma',
  date: 'YYYY-MM-DD',
  dateSlashed: 'M/D/YY',
  time: 'hh:mmA',
  shortTime: 'h:mm a',
  fullTime: 'HH:mm:ss',
  timestamp: 'YYYY-MM-DD hh:mmA',
  timeStampShort: 'M/D/YY h:mmA',
  timeStampFull: 'YYYY-MM-DD hh:mm:ss',
  isoFormat: 'YYYY-MM-DDTHH:mm:ss.SSS',
  chatTime: 'MMM D hh:mmA',
  calMonth: 'MMMM YYYY',
  calDay: 'dddd, MMM D, YYYY',
  modern: 'h:mma | M.D.YY',
};

export const i18nTimePatterns = {
  date: {
    en: 'MM-DD-YYYY',
    es: 'DD/MM/YYYY',
  },
  dateTime: {
    en: 'MM-DD-YYYY hh:mmA',
    es: 'DD/MM/YYYY HH:mm',
  },
};

export const createMoment = (time, tz) => moment.tz(time, tz).locale(i18n.language);
export const getCurrentTimeAtTimezone = (timezone) => moment.tz(moment(), timezone);
export const getDayFromIndex = (dayNum, format = 'dddd', locale = 'en') => {
  moment.locale(locale);
  return moment(dayNum, 'e').format(format);
};
export const getIndexFromDay = (dayName, locale = 'en') => Number(moment(dayName, 'dddd').locale(locale).format('e'));
export const getFormattedJsTime = (jsTimeObj, formatting) => moment(jsTimeObj).locale(i18n.language).format(formatting);
export const getFormattedTimeZone = (time, tz, format) => moment.tz(time, tz).locale(i18n.language).format(format);
//JS TIME === Tue Feb 04 2020 00:00:00 GMT-0800 (Pacific Standard Time)

export const JSDateHelpers = {
  year: (date) => date.getFullYear(),
  month: (date) => String(date.getMonth() + 1).padStart(2, '0'),
  day: (date) => String(date.getDate()).padStart(2, '0'),
  date: function (date) {
    return `${this.year(date)}-${this.month(date)}-${this.day(date)}`;
  },
};

export const getIsNowAfterTime = (time) => moment().isSameOrAfter(moment(time));
export const getIsNowBeforeTime = (time) => moment().isSameOrBefore(moment(time));

export const startOfMonth = (time) => moment(time).startOf('month').toISOString();
export const endOfMonth = (time) => moment(time).endOf('month').toISOString();
