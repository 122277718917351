import { gql } from 'apollo-boost';
import { DANCER_GRID_FRAGMENT } from '../fragments/dancer-grid-fragment';
import { SHIFT_APPLICATION_FRAGMENT } from 'graphql/fragments/shift-application-fragment';

export const GET_BOOKING_GQL = gql`
  ${DANCER_GRID_FRAGMENT}
  ${SHIFT_APPLICATION_FRAGMENT}
  query Shift(
    $bookingId: ID!
    $isAdmin: Boolean!
    $hasLocation: Boolean!
    $blockedByBusinessId: ID
    $inGroupBusinessId: ID
  ) {
    shift(id: $bookingId) {
      id
      shift_type
      slots
      start_time
      end_time
      title
      description
      hardcapped
      accepted_shift_applications_count
      checked_in_shift_applications_count
      checked_out_shift_applications_count
      business {
        id
        timezone
        allow_dancer_reviews
      }
      shift_applications {
        ...shiftApplicationFragment
        dancer {
          ...DancerGridItem
        }
      }
    }
  }
`;
