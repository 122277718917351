import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import * as Icons from 'react-icons/all';
import moment from 'moment';

import { useBusiness } from 'graphql/graph-hooks';

import { ShiftApplication } from '../types';

import { Time, DateTime } from '../helpers';

const Times: FC<Partial<ShiftApplication>> = ({
  created_at,
  is_invite,
  accepted,
  accepted_by,
  accepted_at,
  rejected,
  rejected_by,
  rejected_at,
  standby,
  standby_by,
  standby_at,
  checked_in,
  checked_in_at,
  checked_in_by,
  checked_out,
  checked_out_at,
  checked_out_by,
  marked_missed,
  marked_missed_at,
  marked_missed_by,
}) => {
  const { timezone } = useBusiness();
  const { t } = useTranslation();

  const getModerator = (name: string) =>
    name && (
      <>
        {t('common.by')}: <span className='text-business-purple'>{name}</span>
      </>
    );

  return (
    <div className='mt-2'>
      {created_at && (!accepted_at || (accepted_at && accepted_by) || is_invite) && (
        <p className='mt-0 flex flex-row gap-1' title={DateTime(created_at, timezone)}>
          {is_invite && <Icons.GiMegaphone size='20' title={t('common.invited')} />}
          {t(`common.${is_invite ? 'invited' : 'requested'}`)}:{' '}
          <span className='text-business-purple'>{Time(created_at, timezone)}</span>{' '}
        </p>
      )}
      {(accepted || checked_in || checked_out || marked_missed) && accepted_at && (
        <p className='mt-0' title={DateTime(accepted_at, timezone)}>
          {accepted_by?.name || is_invite
            ? t('common.accepted')
            : t('common.requested') + ' & ' + t('common.preApproved')}
          : <span className='text-business-purple'>{Time(accepted_at, timezone)}</span>{' '}
          {accepted_by && getModerator(accepted_by.name)}
        </p>
      )}
      {rejected && rejected_at && (
        <p className='mt-0' title={DateTime(rejected_at, timezone)}>
          {t('common.rejected')}: <span className='text-business-purple'>{Time(rejected_at, timezone)}</span>{' '}
          {rejected_by && getModerator(rejected_by.name)}
        </p>
      )}
      {standby && standby_at && (
        <p className='mt-0' title={DateTime(standby_at, timezone)}>
          {t('common.standby')}: <span className='text-business-purple'>{Time(standby_at, timezone)}</span>{' '}
          {standby_by && getModerator(standby_by.name)}
        </p>
      )}
      {(checked_in || checked_out) && checked_in_at && (
        <p className='mt-0' title={DateTime(checked_in_at, timezone)}>
          {t('common.checked_in')}: <span className='text-business-purple'>{Time(checked_in_at, timezone)}</span>{' '}
          {checked_in_by && getModerator(checked_in_by.name)}
        </p>
      )}
      {checked_out && checked_out_at && (
        <p className='mt-0' title={DateTime(checked_out_at, timezone)}>
          {t(`common.${!checked_out_by ? 'auto_' : ''}checked_out`)}:{' '}
          <span className='text-business-purple'>
            {moment(checked_in_at).isSame(moment(checked_out_at), 'day')
              ? Time(checked_out_at, timezone)
              : DateTime(checked_out_at, timezone)}
          </span>{' '}
          {checked_out_by && getModerator(checked_out_by.name)}
        </p>
      )}
      {(accepted || marked_missed) && marked_missed_at && (
        <p className='mt-0' title={DateTime(marked_missed_at, timezone)}>
          {t('common.marked_missed')}: <span className='text-business-purple'>{Time(marked_missed_at, timezone)}</span>{' '}
          {marked_missed_by && getModerator(marked_missed_by.name)}
        </p>
      )}
    </div>
  );
};

export default Times;
