import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import S from './photo-verification-page.module.scss';
import { PhotoVerificationList } from './photo-verification-list/photo-verification-list';
import { PHOTO_VERIFICATION_STAGES } from '../../../helpers/enums';
import posthog from 'posthog-js';
import { useTranslation } from 'react-i18next';
import SortSelect from 'components/common/sort-select/sort-select';
import { SearchInput } from '../../common/search-input';
import { debounce } from 'lodash';
import { InputFormSelect } from '../../common/form/form-parts/input-form-select';

const PhotoVerificationPage = () => {
  const { t } = useTranslation();
  const [stage, setState] = useState(PHOTO_VERIFICATION_STAGES.PENDING);
  const handleChangeState = useCallback((e) => setState(e.target.dataset.stage), [setState]);
  const [sortOrder, setSortOrder] = useState('DESC');
  const handleSortOrderChange = useCallback((e) => setSortOrder(e.target.value), [setSortOrder]);
  const [search, setSearch] = useState('');
  const debouncedSetSearch = debounce((text) => setSearch(text), 500);
  const handleSearchChange = (e) => debouncedSetSearch(e.target.value);

  const [includeIncomplete, setIncludeIncomplete] = useState('false');
  const includeIncompleteChange = useCallback((e) => setIncludeIncomplete(e.target.value), [setIncludeIncomplete]);

  const [includeRejected, setIncludeRejected] = useState('false');
  const includeRejectedChange = useCallback((e) => setIncludeRejected(e.target.value), [setIncludeRejected]);


  posthog.capture('PhotoVerificationsPage');

  return (
    <div className={S.pageWrapper}>
      <div className={S.verificationHeader}>
        <h1 className={S.h1Heading}>{t('photoVerificationPage.title')}</h1>

        <div className={S.sortWrapper}>
          <SortSelect label={t('photoVerificationPage.order')} value={sortOrder} onChange={handleSortOrderChange} />
        </div>

        <div className={S.filterWrapper}>
          <SearchInput
            className={S.verificationSearchBar}
            placeholder={t('photoVerificationPage.search')}
            onChange={handleSearchChange}
          />

          <div className={S.filterDropdown}>
            <InputFormSelect
              id='incompleteStatus'
              name='incompleteStatus'
              value={includeIncomplete}
              onChange={includeIncompleteChange}
              options={[
                { label: t('photoVerificationPage.exclude_incomplete'), value: 'false' },
                { label: t('photoVerificationPage.include_incomplete'), value: 'true' },
              ]}
            />
          </div>

          <div className={S.filterDropdown}>
            <InputFormSelect
              id='rejectedStatus'
              name='rejectedStatus'
              value={includeRejected}
              onChange={includeRejectedChange}
              options={[
                { label: t('photoVerificationPage.exclude_rejected'), value: 'false' },
                { label: t('photoVerificationPage.include_rejected'), value: 'true' },
              ]}
            />
          </div>

          <div className={S.verificationBtnGroup}>
            {Object.keys(PHOTO_VERIFICATION_STAGES).map((stageKey) => {
              const stageName = PHOTO_VERIFICATION_STAGES[stageKey];
              return (
                <button
                  key={`VERIFICATION_STAGE_${stageName}`}
                  className={classNames(S.invertedBtnBlue, { [S.active]: stage === stageName })}
                  data-stage={stageName}
                  onClick={handleChangeState}
                >
                  {t(`common.${stageKey.toLowerCase()}`)}
                </button>
              );
            })}
          </div>
        </div>
      </div>

      <PhotoVerificationList
        stage={stage === 'true' ? true : stage === 'false' ? false : null}
        includeIncomplete={includeIncomplete === 'true'}
        includeRejected={includeRejected === 'true'}
        sortOrder={sortOrder}
        search={search}
      />
    </div>
  );
};

PhotoVerificationPage.displayName = 'PhotoVerificationPage';

export { PhotoVerificationPage };
